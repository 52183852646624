import React from "react";
import {
	priceForOne,
	priceForOneSubscription,
	priceForMultipleSubscriptions,
} from "../PriceData";
import "./Pricing.css";

function Pricing() {
	function PriceTag(props) {
		const service2 = (
			<div>
				<p>{props.service1}</p>
				<p>{props.service2}</p>
			</div>
		);

		return (
			<div key={props.id}>
				{props.service2 !== "" ? service2 : <p>{props.service1}</p>}
				<span> {props.price}</span>
			</div>
		);
	}

	function AccordionComponent(props) {
		const price = props.price;

		return (
			<div>
				<input
					type="checkbox"
					name="pricing_accordion"
					id={"section" + props.section}
					className="accordion__input"
				/>
				<label for={"section" + props.section} className="accordion__label">
					{props.name + " (lei)"}
				</label>
				<div className="accordion__content">
					{price.map((value, index) => {
						return (
							<PriceTag
								id={index}
								service1={value.service1}
								service2={value.service2}
								price={value.price}
							/>
						);
					})}
				</div>
			</div>
		);
	}

	return (
		<section id="pricing">
			<div className="title">
				<h1>Preturi</h1>
			</div>
			<div className="pricing-content">
				<div className="accordion">
					<AccordionComponent
						section="1"
						name="pentru 1 sedinta"
						price={priceForOne}
					/>
					<AccordionComponent
						section="2"
						name="pentru abonamente"
						price={priceForOneSubscription}
					/>
					<AccordionComponent
						section="3"
						name="pentru abonamente pentru terapie combinata"
						price={priceForMultipleSubscriptions}
					/>
				</div>
				{/* <img src="walletIlustraison.svg" /> */}
			</div>
			<div id="pricing-card">
				<p>abonamentele sunt valabile 2 luni</p>
				<p>grupurile de pacienti sunt de 7 pacienti / terapeut</p>
				<p>
					terapiile tip masaj, terapie manuala si electroterapie se fac
					individual, doar un pacient / terapeut
				</p>
			</div>
		</section>
	);
}

export default Pricing;
