import React, { useState } from "react";
import "./Navbar.css";

function Navbar() {
	function handleClick() {
		const burger = document.querySelector(".burger");
		const nav = document.querySelector(".nav-links");
		const navLinks = document.querySelectorAll(".nav-links li");
		// Toggle Nav
		nav.classList.toggle("nav-active");
		// Animate Links
		navLinks.forEach((link, index) => {
			if (link.style.animation) {
				link.style.animation = "";
			} else {
				link.style.animation = `navLinkFade 0.5s ease forwards ${
					index / 7 + 0.3
				}s`;
			}
		});
		burger.classList.toggle("toggle");
	}

	function handleScroll(where) {
		const burger = document.querySelector(".burger");
		const nav = document.querySelector(".nav-links");
		const navLinks = document.querySelectorAll(".nav-links li");
		// Toggle Nav
		nav.classList.toggle("nav-active");
		// Animate Links
		navLinks.forEach((link, index) => {
			link.style.animation = "";
		});
		burger.classList.toggle("toggle");

		document.querySelector(where).scrollIntoView({
			behavior: "smooth",
		});
	}

	return (
		<section id="navbar">
			<nav className="navbar">
				<div>
					<a className="logo">Rafiziomed</a>
				</div>
				<ul className="nav-links">
					<li
					// onClick={() => {
					// 	handleScroll("#about");
					// }}
					>
						<a href="#about">Despre</a>
					</li>

					<li
					// onClick={() => {
					// 	handleScroll("#doctors");
					// }}
					>
						<a href="#doctors">Echipa</a>
					</li>
					<li
					// onClick={() => {
					// 	handleScroll("#gallery");
					// }}
					>
						<a href="#gallery">Galerie</a>
					</li>
					<li
					// onClick={() => {
					// 	handleScroll("#pricing");
					// }}
					>
						<a href="#pricing">Preturi</a>
					</li>
					<li
					// onClick={() => {
					// 	handleScroll("#contact");
					// }}
					>
						<a href="#contact">Contact</a>
					</li>
				</ul>
				<div className="burger-wraper">
					<div className="burger" onClick={handleClick}>
						<div className="line1"></div>
						<div className="line2"></div>
						<div className="line3"></div>
					</div>
				</div>
			</nav>
		</section>
	);
}

export default Navbar;
