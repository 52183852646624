import React from "react";
import doctors from "../DoctorsData";
import useWindowDimensions from "../windowDimensions";
import "./Doctors.css";

function Doctors() {
	const { height, width } = useWindowDimensions();

	function DoctorCard(index, src, srcPhone, name, description, cv) {
		let reverse = {
			flexDirection: "row-reverse",
		};
		let reverseImg = null;
		let img = srcPhone;

		if (index % 2 !== 1) {
			reverse = null;
			reverseImg = {
				transform: "scaleX(-1)",
			};
		}

		if (width >= 600) {
			img = src;
		}

		return (
			<div key={index} className="doctor-card" style={reverse}>
				<img src={img} style={reverseImg} />
				<div>
					<h2>{name}</h2>
					<p>{description}</p>
					<span>{cv}</span>
				</div>
			</div>
		);
	}

	return (
		<section id="doctors">
			<div className="title">
				<h1>Echipa</h1>
			</div>
			{doctors.map((value, index) =>
				DoctorCard(
					index,
					value.src,
					value.srcPhone,
					value.name,
					value.description,
					value.cv
				)
			)}
		</section>
	);
}

export default Doctors;
