import staff11 from "./staff/staff11.jpg";
import staff12 from "./staff/staff12.jpg";
import staff21 from "./staff/staff21.jpg";
import staff22 from "./staff/staff22.jpg";
import staff31 from "./staff/staff31.jpg";
import staff32 from "./staff/staff32.jpg";
import staff41 from "./staff/staff41.jpg";
import staff42 from "./staff/staff42.jpg";
import staff112 from "./staff/staff112.jpg";
import staff222 from "./staff/staff222.jpg";
import staff332 from "./staff/staff332.jpg";
import staff442 from "./staff/staff442.jpg";
import staff552 from "./staff/staff552.jpg";
import staff333 from "./staff/staff333.jpg";
import staff662 from "./staff/staff662.jpg";

const doctors = [
	{
		src: staff112,
		srcPhone: staff112,
		name: "Fizioterapeut Andrei Pogangeanu",
		description:
			"Salut, ma numesc Andrei Pogangeanu si sunt un fizioterapeut specializat in recuperare spinala si neurologica.",
		cv: "",
	},
	{
		src: staff222,
		srcPhone: staff222,
		name: "Fizioterapeut Raluca Pogangeanu",
		description:
			"Buna, ma numesc Raluca Pogangeanu si sunt un fizioterapeut principal specializat in recuperare afectiuni ortopedie - traumatologie.",
		cv: "",
	},
	// {
	// 	src: staff333,
	// 	srcPhone: staff333,
	// 	name: "Fizioterapeut Alexandra Oprisor",
	// 	description:
	// 		"Buna, ma numesc Alexandra Oprisor si sunt un fizioterapeut specializat in electroterapie si masaj terapeutic.",
	// 	cv: "",
	// },
	{
		src: staff442,
		srcPhone: staff442,
		name: "Fizioterapeut Alexandru Stan",
		description:
			"Salut, ma numesc Alexandru Stan si sunt un fizioterapeut specializat in terapie manuala.",
		cv: "",
	},
	{
		src: staff552,
		srcPhone: staff552,
		name: "Fizioterapeut Alice Bancila",
		description:
			"Buna, ma numesc Alice Bancila si sunt un fizioterapeut specializat in kinetoterapie, electroterapie si masaj terapeutic.",
		cv: "",
	},
	{
		src: staff662,
		srcPhone: staff662,
		name: "Fizioterapeut Marioara Tuță",
		description:
			"Buna, ma numesc Marioara Tuță si sunt un fizioterapeut specializat in fizioterapie, kinetoterapie si masaj terapeutic.",
		cv: "",
	},
];

export default doctors;
