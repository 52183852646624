import React from "react";
import "./About.css";
import doctorImage from "../doctorIlustraison.svg";

function About() {
	return (
		<section id="about">
			<div className="about">
				<div>
					<h1>Despre Noi</h1>
					<p>
						Cu o bogata experienta in domeniu si peste 15 ani de activitate,
						centrul nostru ofera servicii de kinetoterapie, electroterapie,
						masaj terapeutic si terapie manuala.
					</p>
				</div>
				<img src={doctorImage} />
			</div>
			<div className="svg">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319">
					<path
						fill="#007fff"
						fill-opacity="1"
						d="M0,64L80,80C160,96,320,128,480,128C640,128,800,96,960,122.7C1120,149,1280,235,1360,277.3L1440,320L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
					></path>
				</svg>
			</div>
			<div className="about-card">
				<p>
					Oferim servicile enumerate mai sus pentru urmatoarele tipuri de
					afectiuni :
				</p>
				<p>
					- neurologice (pareze, paralizii ale nervilor periferici, hemipareze
					post AVC, para/tetrapareze)
				</p>
				<p>
					- neurochirurgicale (hernii de disc cevicale, toracale, lombare,
					operate si neoperate, spondilolistezis operat/neoperat , deficite
					motorii in contextul patologiei oncologice spinale si cerebrale)
				</p>
				<p>
					- osteoarticulare (recuperare pentru artroplastii de genunchi, sold,
					umar, artroze)
				</p>
				<p>- posttraumatice (ligamentoplastii, fracturi, entorse, etc.)</p>
				<p>
					- post chirurgie oncologica (tumori de san, diverse tipuri de tumori
					operate care determina deficite functionale)
				</p>
				<p>- respiratorii (post covid).</p>
				<p>
					De asemenea efectuam kinetoprofilaxia gravidelor si recuperare dupa
					nastere (diastazis rectii).
				</p>
			</div>
		</section>
	);
}

export default About;
