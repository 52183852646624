import React from "react";
import Navbar from "./Navbar";
import Contact from "./Contact";
import Footer from "./Footer";
import About from "./About";
import Pricing from "./Pricing";
import Gallery from "./Gallery";
import Doctors from "./Doctors";
import "../styles.css";

function App() {
	return (
		<div>
			<Navbar />
			<About />
			<Doctors />
			<Gallery />
			<Pricing />
			<Contact />
			<Footer />
			{/* <video src="video.mp4" autoPlay muted loop id="myVideo" /> */}
		</div>
	);
}

export default App;
