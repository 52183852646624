import React from "react";
import "./Gallery.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import photo1 from "../gallery/photo1.JPG";
import photo2 from "../gallery/photo2.JPG";
import photo3 from "../gallery/photo3.JPG";
import photo4 from "../gallery/photo4.JPG";
import photo5 from "../gallery/photo5.JPG";
import photo6 from "../gallery/photo6.JPG";
import photo7 from "../gallery/photo7.JPG";
import photo8 from "../gallery/photo8.JPG";
import photo9 from "../gallery/photo9.JPG";
import photo10 from "../gallery/photo10.JPG";
import photo11 from "../gallery/photo11.JPG";
import photo12 from "../gallery/photo12.JPG";
import photo13 from "../gallery/photo13.JPG";
import photo14 from "../gallery/photo14.JPG";
import photo15 from "../gallery/photo15.JPG";
import photo16 from "../gallery/photo16.JPG";
import photo17 from "../gallery/photo17.JPG";
import photo18 from "../gallery/photo18.JPG";

function Gallery() {
	return (
		<section id="gallery">
			<div className="title">
				<h1>Galerie</h1>
			</div>
			<div className="gallery">
				<div>
					<OwlCarousel
						className="owl-theme"
						margin={10}
						responsive={{
							0: {
								items: 1,
							},
							650: {
								items: 2,
							},
							1100: {
								items: 3,
							},
							1700: {
								items: 4,
							},
						}}
					>
						{/* <div className="item">
							<img src="https://www.rafiziomed.ro/wp-content/gallery/sediu_nou_2021/whatsapp-image-2021-01-04-at-10-47-14.jpg" />
						</div>
						<div className="item">
							<img src="https://www.rafiziomed.ro/wp-content/gallery/sediu_nou_2021/whatsapp-image-2021-01-04-at-10-47-13-1.jpg" />
						</div>
						<div className="item">
							<img src="https://www.rafiziomed.ro/wp-content/gallery/sediu_nou_2021/whatsapp-image-2021-01-04-at-09-00-39.jpg" />
						</div>
						<div className="item">
							<img src="https://www.rafiziomed.ro/wp-content/gallery/sediu_nou_2021/whatsapp-image-2021-01-04-at-09-00-39-1.jpg" />
						</div>

						<div className="item">
							<img src="https://www.rafiziomed.ro/wp-content/gallery/sediu_nou_2021/whatsapp-image-2021-01-04-at-09-00-41-2.jpg" />
						</div>

						<div className="item">
							<img src="https://www.rafiziomed.ro/wp-content/gallery/sediu_nou_2021/whatsapp-image-2021-01-04-at-10-47-13-2.jpg" />
						</div> */}
						<div className="item">
							<img src={photo1} />
						</div>
						<div className="item">
							<img src={photo2} />
						</div>
						<div className="item">
							<img src={photo3} />
						</div>
						<div className="item">
							<img src={photo4} />
						</div>
						<div className="item">
							<img src={photo5} />
						</div>
						<div className="item">
							<img src={photo6} />
						</div>
						<div className="item">
							<img src={photo7} />
						</div>
						<div className="item">
							<img src={photo8} />
						</div>
						<div className="item">
							<img src={photo9} />
						</div>
						<div className="item">
							<img src={photo10} />
					 	</div>
						<div className="item">
							<img src={photo11} />
						</div>
						<div className="item">
							<img src={photo12} />
						</div>
						<div className="item">
							<img src={photo13} />
						</div>
						<div className="item">
							<img src={photo14} />
						</div>
						<div className="item">
							<img src={photo15} />
						</div>
						<div className="item">
							<img src={photo16} />
						</div>
						<div className="item">
							<img src={photo17} />
						</div>
						<div className="item">
							<img src={photo18} />
						</div>
					</OwlCarousel>
				</div>
			</div>
		</section>
	);
}

export default Gallery;
