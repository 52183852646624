const priceForOne = [
	{
		service1: "Evaluare",
		service2: "",
		price: 200,
	},
	{
		service1: "Sedinta kinetoterapie(50min)",
		service2: "",
		price: 250,
	},
	{
		service1: "Masaj terapeutic(30min)",
		service2: "",
		price: 150,
	},
	{
		service1: "Masaj terapeutic(50min)",
		service2: "",
		price: 200,
	},
	{
		service1: "Sedinta electroterapie (3 proceduri)",
		service2: "",
		price: 110,
	},
	{
		service1: "Terapie Manuala",
		service2: "",
		price: 300,
	},
	{
		service1: "Terapie Tecar(30min)",
		service2: "",
		price: 100,
	},
	{
		service1: "Dryneedeling + Terapie Tecar/Ultrasunet",
		service2: "",
		price: 150,
	},
];

const priceForOneSubscription = [
	{
		service1: "8 sedinte kinetoterapie",
		service2: "",
		price: 1600,
	},
	{
		service1: "10 sedinte kinetoterapie",
		service2: "",
		price: 1650,
	},
	{
		service1: "12 sedinte kinetoterapie",
		service2: "",
		price: 1700,
	},
	{
		service1: "10 sedinte masaj terapeutic(30min)",
		service2: "",
		price: 1350,
	},
	{
		service1: "10 sedinte masaj terapeutic(50min)",
		service2: "",
		price: 1800,
	},
	{
		service1: "10 sedinte electroterapie",
		service2: "",
		price: 990,
	},
	{
		service1: "10 sedinte tecar",
		service2: "",
		price: 900,
	},
];

const priceForMultipleSubscriptions = [
	{
		service1: "10 sedinte kinetoterapie +",
		service2: "10 sedinte electroterapie + 10 sedinte masaj terapeutic(30min)",
		price: 3300,
	},
	{
		service1: "10 sedinte electroterapie +",
		service2: "10 sedinte masaj terapeutic(30min)",
		price: 2300,
	},
	{
		service1: "10 sedinte kinetoterapie +",
		service2: "10 sedinte masaj terapeutic(30min)",
		price: 2700,
	},
	{
		service1: "10 sedinte kinetoterapie +",
		service2: "10 sedinte masaj terapeutic(50min)",
		price: 3000,
	},
	{
		service1: "12 sedinte kinetoterapie +",
		service2: "10 sedinte masaj terapeutic(30min)",
		price: 2800,
	},
	{
		service1: "12 sedinte kinetoterapie +",
		service2: "10 sedinte masaj terapeutic(50min)",
		price: 3200,
	},
	{
		service1: "10 sedinte kinetoterapie +",
		service2: "10 sedinte electroterapie",
		price: 2500,
	},
	{
		service1: "12 sedinte kinetoterapie +",
		service2: "10 sedinte electroterapie",
		price: 2600,
	},
];

export { priceForOne, priceForOneSubscription, priceForMultipleSubscriptions };
