import React from "react";
import GoogleMap from "./GoogleMap";
import "./Contact.css";

function Contact() {
  return (
    <section id="contact">
      <div className="title">
        <h1>Contactati-ne</h1>
      </div>
      <div className="contact">
        <div className="details">
          <div className="info">
            <div className="icon-box">
              <i class="fas fa-home"></i>
            </div>
            <div className="content">
              <h4>Adresa</h4>
              <p>Str. Eminescu nr. 163, parter,</p>
              <p>intrarea A, Bucuresti</p>
            </div>
          </div>
          <div className="info">
            <div className="icon-box">
              <i class="fas fa-phone"></i>
            </div>
            <div className="content">
              <h4>Programeaza-te</h4>
              <p>+40742 051 300</p>
              <p>rafiziomed2004@gmail.com</p>
            </div>
          </div>
          <div className="info">
            <div className="icon-box">
              <i class="fas fa-clock"></i>{" "}
            </div>
            <div className="content">
              <h4>Program</h4>
              <p>
                <span>Luni - Vineri:</span> 9:00 - 20:00
              </p>
            </div>
          </div>
        </div>
        <GoogleMap />
      </div>
    </section>
  );
}

export default Contact;
